<template>
    <div v-if="showMessage" class="samMessage">
        <div v-html="htmlRow"></div>
        <div>
            <v-btn class="btn" @click="closeit()" v-if="showExit" style="background:darkgreen;color:#fff;width:120px;margin:5px">{{lang.close}}</v-btn>
            <v-btn class="btn" @click="logout()" v-if="showLogout" style="background:darkred;color:#fff;width:120px;margin:5px">{{lang.logout}}</v-btn>
            <v-btn class="btn" @click="refresh()" v-if="showRef" style="background:darkblue;color:#fff;width:120px;margin:5px">تحديث</v-btn>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
export default {
    data() {
        return {
            // showMessage: true,
            htmlRow: '',
            showLogout: false,
            showRef: false,
            showExit: false,
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        showMessage: function(){
            if(this.htmlRow != ''){
                return true;
            }
            return false;
        }
    },
    methods: {
        closeit() {
            this.htmlRow = ''
            this.showLogout = false
            this.showRef = false
            this.showExit = false

            const post = new FormData();
            post.append('type', "iClickIt");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("data[do]", "getRes")
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) =>{
                this.htmlRow = '';
            })
        },
        logout() {
            this.showLogout = false
            this.showRef = false
            this.showExit = false
            const post = new FormData();
            post.append('type', "iClickIt");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("data[do]", "getRes")
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) =>{
                this.$router.push({path: '/logout'})
            })
            this.$router.push({path: '/logout'})
        },
        refresh() {
            this.htmlRow = ''
            this.showLogout = false
            this.showRef = false
            this.showExit = false
            const post = new FormData();
            post.append('type', "iClickIt");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("data[do]", "getRes")
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) =>{
                location.reload();
            })
            // location.reload();
        },
        getMessage(){
            const post = new FormData();
            post.append('type', "getSamMessage");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("data[do]", "getRes")
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) =>{

                if(response.data.results.data.message != ''){
                    
                    this.htmlRow = decodeURIComponent(escape(atob(response.data.results.data.message))) 
                    if(response.data.results.data.logoutBtn){
                        this.showLogout = true;
                    }else{
                        this.showLogout = false;
                    }
                    if(response.data.results.data.showRef){
                        this.showRef = true;
                    }else{
                        this.showRef = false;
                    }
                    if(response.data.results.data.showExit){
                        this.showExit = true;
                    }else{
                        this.showExit = false;
                    }
                }else{
                    this.htmlRow = ''
                }
            })
        }
    },
    created() {
        this.getMessage();
    }
}
</script>

<style>
.samMessage{
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background:#ffffffda; 
    z-index: 10000000;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
</style>